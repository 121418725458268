import React from "react"
import BlockArea from "gatsby-plugin-pressless"
import ProjectPullquote from "../components/blocks/project-pullquote"
import ProjectCircularImages from "../components/blocks/project-circular-images"
import * as Chakra from "@chakra-ui/react"
import HeroImage from "../components/hero-image"
import FullWidthImage from "../components/blocks/full-width-image"
import ProjectLargeImage from "../components/blocks/project-large-image"
import ProjectSlider from "../components/blocks/project-slider"
import ProjectDetailHeader from "../components/blocks/project-detail-header"
import SideBySideImages from "../components/blocks/side-by-side-images"
import { graphql } from "gatsby"
import * as htmlEntities from "html-entities"
import { Seo } from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import ProjectSeeMore from "../components/blocks/project-see-more"
import ModeSwitch from "../components/blocks/mode-switch";

const wpDigital = ({ data, pageContext }) => {
  return (
      <Chakra.Flex width="100%" flexGrow={1}>
        <Helmet>
          <style>{`  :root { --hero-text-col: #fff; --background-col: #000; --text-col: #fff; } `}</style>
        </Helmet>
        {data?.wpDigital && (
            <Seo
                post={data?.wpDigital}
                title={
                  data?.wpDigital?.seo?.title
                      ? htmlEntities.decode(data?.wpDigital?.seo?.title)
                      : "RED"
                }
            />
        )}
        <BlockArea
            id="page"
            data={data?.wpDigital}
            isEnabled={pageContext.isPressLess}
        >
          <HeroImage />
          <ProjectCircularImages />
          <ProjectPullquote />
          <FullWidthImage />
          <ProjectLargeImage />
          <ProjectSlider />
          <ProjectDetailHeader />
          <ProjectSeeMore />
          <SideBySideImages />
          <ModeSwitch />
        </BlockArea>
      </Chakra.Flex>
  )
}

export const query = graphql`
  query ($id: String) {
    site {
      buildTime
    }
    wpDigital(id: { eq: $id }) {
      template {
        templateName
      }
      date(formatString: "LL")
      title
      presslesscontent
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`

export default wpDigital
